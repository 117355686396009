import React, { useState, useEffect } from "react";

const ListingFilter = ({
  state,
  actions,
  children,
  handleSearchKeyword = false,
  initialKeyword = "",
}) => {
  const [keyword, setKeyword] = useState("");
  const [isFilterMobileOpen, setIsFilterMobileOpen] = useState(false);

  useEffect(() => {
    setKeyword(initialKeyword);
  }, [initialKeyword]);

  const closeListingFilter = () => {
    setIsFilterMobileOpen(false);
  };

  const openListingFilter = () => {
    setIsFilterMobileOpen(true);
  };

  return (
    <>
      <div
        className={
          isFilterMobileOpen
            ? "listing-filter listing-filter-mobile-open"
            : "listing-filter"
        }
      >
        {handleSearchKeyword && (
          <div className="listing-filter-container">
            {isFilterMobileOpen && (
              <div
                className="listing-filter-close"
                onClick={closeListingFilter}
              ></div>
            )}
            <div className="listing-filter-title">Filter</div>
            <div className="listing-filter-body">
              <form onSubmit={e => handleSearchKeyword({ e, keyword })}>
                <input
                  type="text"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
                <input className="button-search" type="submit" value="Search" />
              </form>
            </div>
          </div>
        )}
        {children}
        {isFilterMobileOpen && (
          <div
            className="listing-filter-option-cta"
            onClick={closeListingFilter}
          >
            Apply Options
          </div>
        )}
      </div>
      <div className="cta-filter-mobile" onClick={openListingFilter}>
        <span>Filter</span>
        <i className="fas fa-sliders-h"></i>
      </div>
    </>
  );
};

export default ListingFilter;
