import React, { useState } from "react";

const ListingFilterItem = ({
  filterKey,
  title,
  items,
  handleFunction,
  initialOptions = [],
  style = "button",
}) => {
  const [options, setOptions] = useState(initialOptions);

  const handleClick = val => {
    const newOptions =
      options.indexOf(val) === -1
        ? [...options, val]
        : options.filter(item => item !== val);
    handleFunction(filterKey, newOptions);
    setOptions(newOptions);
  };

  return (
    <div className="listing-filter-container">
      <div className="listing-filter-title listing-filter-item-title">
        {title}
      </div>
      {items && items?.length > 0 && (
        <div className="listing-filter-body">
          {items.map((item, index) => {
            if (style == "button") {
              return (
                <div
                  key={index}
                  className={
                    options.includes(item?.value)
                      ? "listing-filter-option checked"
                      : "listing-filter-option"
                  }
                  onClick={e => handleClick(item?.value)}
                  dangerouslySetInnerHTML={{
                    __html: item.label,
                  }}
                ></div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="listing-filter-option option-checkbox"
                >
                  <label className="container">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.label,
                      }}
                    ></span>
                    <input
                      key={index}
                      type="checkbox"
                      value={item?.value}
                      checked={options.includes(item?.value)}
                      onChange={e => handleClick(item?.value)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default ListingFilterItem;
