import React from "react";
import { Link } from "gatsby";

const BlogCard = ({ image, category, title, excerpt, link }) => {
  return (
    <div className="card-item card-item-no-padding">
      {image && (
        <div className="card-item-thumbnail">
          <Link to={link}>
            <img src={image} alt={title} />
          </Link>
        </div>
      )}
      <div className="card-item-inner-content">
        {category && <div className="card-item-author">{category}</div>}
        {title && (
          <Link to={link}>
            <h2
              className="card-item-title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
          </Link>
        )}
        {excerpt && (
          <div
            className="card-item-excerpt"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></div>
        )}
        {/* <Link className="card-item-read-more" to={link}>
          Read more
        </Link> */}
      </div>
    </div>
  );
};

export default BlogCard;
