import React, { useState, useRef, useEffect } from "react";

import Loading from "./loading";
import BlogCard from "./blog-card";
import ListingFilter from "./listing-filter";
import ListingFilterItem from "./listing-filter-item";

const BlogListing = ({ site, data, dataCategory }) => {
  const [y, setY] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [listingHeight, setListingHeight] = useState(0);
  const [listingTreshold, setListingTreshold] = useState(0);
  const [listingData, setListingData] = useState(() => {
    if (data && data?.nodes?.length > 0) {
      const filteredData = [];
      for (let i = 0; i < data?.nodes?.length; i++)
        filteredData.push(data.nodes[i]);
      return filteredData;
    }
    return false;
  });
  const [filterData, setfilterData] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const listingRef = useRef();
  const listingItemsRef = useRef();
  const batasBawah = useRef();

  useEffect(() => {
    if (windowHeight === 0) {
      let listingTresholdSticky = 265;
      if (listingItemsRef.current.offsetWidth === 895)
        listingTresholdSticky = 730;
      if (listingItemsRef.current.offsetWidth === 809)
        listingTresholdSticky = 670;
      if (listingItemsRef.current.offsetWidth === 658)
        listingTresholdSticky = 740;
      setWindowHeight(window.innerHeight);
      setListingHeight(listingItemsRef.current.offsetHeight);
      setListingTreshold(listingTresholdSticky);
    }
    window.addEventListener("scroll", e => {
      setY(window.scrollY);
    });
  }, [y]);

  const isContainKeyword = ({ text = "", keyword = false }) => {
    return !keyword && keyword === ""
      ? true
      : keyword &&
        keyword !== "" &&
        keyword !== undefined &&
        text?.includes(keyword)
      ? true
      : false;
  };

  const getListing = async ({ filterParams = false }) => {
    setListingData(false);
    let listingItems = false;
    if (data && data?.nodes?.length > 0) {
      listingItems = [];
      data.nodes.map(item => {
        let isValidItem = true;
        if (filterParams?.filterData?.category?.length > 0) {
          let isItemInCategory = false;
          filterParams.filterData.category.map(catId => {
            if (item?.categories?.nodes?.length > 0) {
              item.categories.nodes.map(itemCategory => {
                if (itemCategory?.databaseId === catId) isItemInCategory = true;
              });
            }
          });
          if (isItemInCategory) {
            isValidItem = isContainKeyword({
              text: item?.title?.toLowerCase(),
              keyword: filterParams?.keyword?.toLowerCase(),
            });
          } else isValidItem = false;
        } else {
          isValidItem = isContainKeyword({
            text: item?.title?.toLowerCase(),
            keyword: filterParams?.keyword?.toLowerCase(),
          });
        }
        if (isValidItem) listingItems.push(item);
      });
    }
    setListingData(listingItems);
  };

  const handleSearchKeyword = ({ e, keyword }) => {
    e.preventDefault();
    listingRef.current.scrollIntoView();
    // actions.theme.closeListingFilter();
    setSearchKeyword(keyword);
    const filterParams = {
      keyword: keyword,
      filterData: filterData,
    };
    getListing({ filterParams });
  };

  const handleOptions = (key, options) => {
    listingRef.current.scrollIntoView();
    // actions.theme.closeListingFilter();
    let currentFilterData = !filterData ? [] : filterData;
    currentFilterData[key] = options;
    setfilterData(currentFilterData);
    const filterParams = {
      keyword: searchKeyword,
      filterData: currentFilterData,
    };
    getListing({ filterParams });
  };

  const filterListing = [
    {
      label: "Options",
      option: "category",
    },
  ];

  let filterListingOptions = [];
  filterListing.map(item => {
    filterListingOptions[item.option] = false;
    if (dataCategory?.nodes?.length > 0) {
      filterListingOptions[item.option] = [];
      dataCategory.nodes.forEach(entry => {
        if (entry.databaseId !== 1)
          filterListingOptions[item.option].push({
            value: entry.databaseId,
            label: entry.name,
          });
      });
    }
  });

  return (
    <div
      ref={listingRef}
      className={
        y > windowHeight && y < listingHeight - listingTreshold
          ? "custom-post-listing custom-post-listing-sticky"
          : "custom-post-listing"
      }
    >
      <ListingFilter
        initialKeyword={searchKeyword}
        handleSearchKeyword={handleSearchKeyword}
      >
        {filterListing &&
          filterListing.map(item => {
            return (
              <ListingFilterItem
                key={item.option}
                filterKey={item.option}
                title={item.label}
                items={filterListingOptions[item.option]}
                handleFunction={handleOptions}
                style="checkbox"
              />
            );
          })}
      </ListingFilter>
      <div ref={listingItemsRef} className="custom-post-listing-items">
        {!listingData && <Loading />}
        {listingData &&
          (listingData?.length > 0 ? (
            <>
              {listingData.map((listingItem, index) => {
                const postImage =
                  listingItem?.featuredImage?.node?.mediaDetails?.sizes
                    ?.length > 0
                    ? listingItem.featuredImage.node.mediaDetails.sizes.find(
                        media => media?.name == "medium_large"
                      )
                    : false;
                return (
                  <BlogCard
                    key={index}
                    image={
                      postImage?.sourceUrl ||
                      `${site?.siteMetadata?.cmsURL}/wp-content/uploads/2022/07/iStock-621581680.jpg`
                    }
                    category={
                      listingItem?.categories?.nodes?.[0]?.name || false
                    }
                    title={listingItem?.title || false}
                    excerpt={listingItem?.excerpt || false}
                    link={listingItem?.uri || false}
                  />
                );
              })}
            </>
          ) : (
            <p className="no-data">
              Sorry, can't find any article in this category.
            </p>
          ))}
      </div>
      <div ref={batasBawah} className="batas-bawah"></div>
    </div>
  );
};

export default BlogListing;
